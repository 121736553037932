import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout/Layout';
import AboutUsContent from '../components/aboutUsContent/AboutUsContent';
import {useAboutUsContent} from '../graphql/aboutUs/aboutUs';

const AboutUs = () => {
  const [aboutUsContent] = useAboutUsContent();

  return (
    <Layout>
      <SEO title="Despre noi" />
      <AboutUsContent content={aboutUsContent} />
    </Layout>
  );
};

export default AboutUs;
