import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './AboutUsContent.less';
import HeroVideo from '../heroVideo/HeroVideo';
import Carousel from '../carousel/Carousel';

/* eslint react/no-danger: 0 */
const AboutUsContent = ({content}) => (
  <>
    <Container fluid style={{padding: 0}}>
      <HeroVideo />
    </Container>
    <Container fluid className="aboutUs">
      <h1 className="aboutUs__heading mt-3 text-center">
        {content?.generalInformation?.heading}
      </h1>
      <div className="aboutUs__borderBottom mx-auto mb-5" />
      <Row className="align-items-center">
        <Col sm={12} xl={6} className="my-3">
          <Carousel
            images={content?.generalInformation?.images}
            className="animate__animated animate__backInLeft"
          />
        </Col>
        <Col sm={12} xl={6}>
          <div className="aboutUs__info animate__animated animate__backInRight">
            <div className="aboutUs__infoCard">
              {content?.generalInformation?.content?.aboutUs?.map(
                (item, index) => (
                  <p
                    className="aboutUs__paragraph"
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}
                  />
                ),
              )}
            </div>
            <h1 className="aboutUs__heading my-4">
              {content?.generalInformation?.content?.administrators?.heading}
            </h1>
            {content?.generalInformation?.content?.administrators?.listOfAdministrators?.map(
              (item, index) => (
                <a href={`tel:${item.phoneNumber}`} key={index}>
                  <div className="aboutUs__administratorCard my-3">
                    <div>
                      <p className="aboutUs__paragraph">
                        <b>{item.name}</b>
                      </p>
                      {item.phoneNumber}
                    </div>
                    <div>
                      <i className="im im-phone" />
                    </div>
                  </div>
                </a>
              ),
            )}
          </div>
        </Col>
      </Row>
      <div className="aboutUs__services">
        <h1 className="aboutUs__heading mb-1 text-center">
          {content?.services?.heading}
        </h1>
        <div className="aboutUs__borderBottom mx-auto mb-5" />
        <Row className="align-items-center">
          <Col sm={12} xl={6}>
            <div className="aboutUs__serviceCard my-4 animate__animated animate__backInLeft">
              <p className="aboutUs__paragraph">
                {content?.services?.listHeading}
              </p>
              {content?.services?.listOfServices?.map((item, index) => (
                <div className="aboutUs__service" key={index}>
                  <i className="im im-angle-right small pt-2" />
                  <p className="aboutUs__paragraph">{item}</p>
                </div>
              ))}
            </div>
          </Col>
          <Col sm={12} xl={6}>
            <Carousel
              images={content?.services?.images}
              className="animate__animated animate__backInRight"
            />
          </Col>
        </Row>
      </div>
    </Container>
  </>
);

export default AboutUsContent;
