import React from 'react';
import './HeroVideo.less';

const HeroVideo = () => (
  <div className="heroVideo">
    <div className="heroVideo__overlayImage" />
    <div
      id="wistiaHeroVideo"
      className="wistia_embed wistia_async_grvxee5ocj heroVideo__backgroundVideo"
    />
  </div>
);

export default HeroVideo;
