import {graphql, useStaticQuery} from 'gatsby';

export const useAboutUsContent = () => {
  const {allAboutUsJson} = useStaticQuery(
    graphql`
      query AboutUsContent {
        allAboutUsJson {
          nodes {
            generalInformation {
              content {
                aboutUs
                administrators {
                  heading
                  listOfAdministrators {
                    name
                    phoneNumber
                  }
                }
              }
              heading
              images {
                childImageSharp {
                  fluid(maxHeight: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            services {
              heading
              images {
                childImageSharp {
                  fluid(maxHeight: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              listHeading
              listOfServices
            }
          }
        }
      }
    `,
  );
  return allAboutUsJson.nodes;
};
