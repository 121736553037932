import React from 'react';
import Img from 'gatsby-image';
import BootstrapCarousel from 'react-bootstrap/Carousel';
import './Carousel.less';

const Carousel = ({images}) => {
  return (
    <BootstrapCarousel
      className="carousel"
      indicators={false}
      prevIcon={<i className="im im-angle-left carousel__controlIcons" />}
      nextIcon={<i className="im im-angle-right carousel__controlIcons" />}
    >
      {images.map((image, index) => (
        <BootstrapCarousel.Item className="carousel__image" key={index}>
          <Img fluid={image.childImageSharp.fluid} />
        </BootstrapCarousel.Item>
      ))}
    </BootstrapCarousel>
  );
};

export default Carousel;
